import baseComponent from '@/scripts/baseComponent';
import authApi from '@/api/common/auth';
import { API_CALL_STATUS } from "@/constant/config";
import { ADMIN_FUNCTIONS_MODIFY_TYPE } from "@/constant/systemManagerStatus";
import { mapState } from "vuex";
const queryString = require('query-string');

export default {

    extends: baseComponent,

    data() {
        return {
            functionalGroups: [],
            decentralized: [],
            request: {
                id: null,
                code: null,
                name: null,
                parentId: null,
                groupId: null,
                description: null
            }
        };
    },

    computed: {
        ...mapState({
            user: state => state.auth.user,
            listServicePack: state => state.common.listServicePack,
        }),
    },

    created() {

    },

    mounted() {
        this.getData();
    },

    methods: {

        // Close this pop-up
        closePopup() {
            this.$emit("processingEvent", { event: "closePopup", data: "admin-functions-create" });
        },

        // Get data
        getData() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            this.isCheckAll = false;
            authApi.decentralizedView(queryString.stringify({}))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        this.functionalGroups = responseData.functionalGroups;
                        this.decentralized = this.decentralized.concat(responseData.decentralized);
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    // location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },

        // Modify IP
        modifyFunction() {
            this.feedStatusLoading(API_CALL_STATUS.LOADING);
            let request = {
                listData: this.element,
                type: ADMIN_FUNCTIONS_MODIFY_TYPE.INFORMATION,
                id: this.request.id,
                code: this.request.code,
                name: this.request.name,
                parentId: this.request.parentId,
                groupId: this.request.groupId,
                description: this.request.description,
                deviceInformation: JSON.stringify(this.getInformationClient()),
            }
            authApi.adminFunctionsModify(request)
                .then((res) => {
                    try {
                        this.closePopup();
                        this.$emit("processingEvent", { event: "performAction", data: { action: "refresh" } });
                        this.feedStatusLoading(API_CALL_STATUS.SUCCESS);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(API_CALL_STATUS.FAILED);
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(API_CALL_STATUS.FAILED);
                });
        },
    },
}